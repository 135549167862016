export const brandList = [
  { name: "evolution", width: 89, pcWidth: 153 },
  { name: "taishan", width: 55, pcWidth: 95 },
  { name: "bombay", width: 87, pcWidth: 149 },
  { name: "ezugi", width: 58, pcWidth: 101 },
  { name: "tmojos", width: 55, pcWidth: 95 },
  // 5
  { name: "playngo", width: 75, pcWidth: 93 },
  { name: "pragmatic", width: 54, pcWidth: 140 },
  { name: "winfast", width: 29.6, pcWidth: 51 },
  { name: "nolimit", width: 76.6, pcWidth: 112 },
  { name: "rela", width: 73.8, pcWidth: 108 },
  // 10
  { name: "hacksaw", width: 80, pcWidth: 117 },
  { name: "habanero", width: 86, pcWidth: 127 },
  { name: "mannaplay", width: 97, pcWidth: 142 },
  { name: "netent", width: 73.8, pcWidth: 108 },
  { name: "bigtime", width: 85, pcWidth: 124.8 },
  // 15
  { name: "redTiger", width: 110, pcWidth: 152 },
  { name: "bluePrint", width: 98, pcWidth: 135 },
  { name: "aux-icon", width: 100.8, pcWidth: 128 },
  { name: "elk", width: 66, pcWidth: 84 },
  { name: "kalamba", width: 98, pcWidth: 124.8 },
  // 20
  { name: "wazdan", width: 111.8, pcWidth: 142 },
  { name: "pg", width: 96.5, pcWidth: 122.5 },
];
